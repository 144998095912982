import PropTypes from 'prop-types'

export const articlePropType = PropTypes.shape({
  title: PropTypes.string,
  content: PropTypes.string,
  slug: PropTypes.string,
  tags: PropTypes.array,
  images: PropTypes.object,
  category: PropTypes.object,
  author: PropTypes.object,
  createdAt: PropTypes.string,
  excerpt: PropTypes.string,
})
