import React, { useContext } from 'react'
import Head from 'next/head'
import { PropTypes } from 'prop-types'
import { ThemeContext } from 'styled-components'

/**
 * ArticleHead
 */

export const ArticleHead = ({ data }) => {
  const theme = useContext(ThemeContext)
  const { excerpt, images, slug, title } = data

  return (
    <Head>
      <title>{`${title} - ${process.env.NEXT_PUBLIC_SITE_NAME}`}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="shortcut icon" href="/favicon.ico" />
      <link
        href="https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;600;700&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Nunito+Sans:wght@400;600;700;800&display=block"
        rel="stylesheet"
      />
      <meta name="theme-color" content={theme.colors.primary} />

      <meta name="title" content={title} />
      <meta name="description" content={excerpt} />

      <meta property="og:type" content="article" key="ogType" />
      <meta property="og:url" content={`${process.env.NEXT_PUBLIC_SITE_URL}/${slug}`} />
      <meta property="og:title" content={title} key="ogTitle" />
      <meta property="og:description" content={excerpt} key="ogDescription" />
      <meta property="og:image" content={images.original} key="ogImage" />

      <meta property="twitter:url" content={`${process.env.NEXT_PUBLIC_SITE_URL}/${slug}`} />
      <meta property="twitter:title" content={title} key="twitterCardTitle" />
      <meta property="twitter:description" content={excerpt} key="twitterCardDescription" />
      <meta property="twitter:image" content={images.original} key="twitterCardImage" />

      <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon-152x152.png" />
      <link rel="apple-touch-icon" sizes="120x120" href="/apple-touch-icon-120x120.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon-76x76.png" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      <meta name="apple-mobile-web-app-title" content={process.env.NEXT_PUBLIC_SITE_NAME} />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
    </Head>
  )
}

/**
 * PropTypes
 */

ArticleHead.propTypes = {
  data: PropTypes.object,
}
