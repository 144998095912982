import React from 'react'
import { config } from 'react-awesome-styled-grid'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/**
 * ArticleFeaturedHero
 */

export const ArticleFeaturedHero = ({ imageSrc }) => {
  return <Image src={imageSrc} />
}

/**
 * PropTypes
 */

ArticleFeaturedHero.propTypes = {
  imageSrc: PropTypes.string.isRequired,
}

/**
 * Styles
 */

const Image = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  background: url(${props => props.src}) no-repeat;
  background-size: cover;
  margin-bottom: 30px;

  ${props => config(props).media.lg`
    height: 80vh;
  `}
`
