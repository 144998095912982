import React from 'react'
import { config } from 'react-awesome-styled-grid'
import { FacebookProvider, Share } from 'react-facebook'
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/**
 * Constants
 */

const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID

/**
 * ArticleSocialLinks
 */

export const ArticleSocialLinks = ({ title, slug }) => {
  return (
    <Container>
      <FacebookProvider appId={FACEBOOK_APP_ID}>
        <Share href={`${process.env.NEXT_PUBLIC_SITE_URL}/${slug}`}>
          {({ handleClick, loading }) => (
            <IconContainer onClick={handleClick} disabled={loading} rel="noopener noreferrer" target="_blank">
              <Icon color="black" icon={faFacebookF} />
            </IconContainer>
          )}
        </Share>
      </FacebookProvider>
      <IconContainer
        href={`https://twitter.com/intent/tweet?text=${title}&url=https://djmagla.com/${slug}&via=djmagla`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon color="black" icon={faTwitter} />
      </IconContainer>
    </Container>
  )
}

/**
 * PropTypes
 */

ArticleSocialLinks.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
}

/**
 * Styles
 */

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const IconContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-right: 1px solid ${props => props.theme.colors.separator};
  padding-right: 10px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
    border: 0;
  }

  ${props => config(props).media.lg`
    width: 35px;
    height: 35px;
  `}
`

const Icon = styled(FontAwesomeIcon)`
  ${props => config(props).media.lg`
    width: 1.5em !important;
    height: 1.5em !important;
  `}
`
