import React from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Routes } from '~/helpers/constants'

/**
 * ArticleTags
 */

export const ArticleTags = ({ tags }) => {
  return (
    <Container>
      <span>Tags: </span>
      {tags.map(item => {
        return (
          <Tag key={item.id}>
            <Link href={`${Routes.TAGS}/[slug]`} as={`${Routes.TAGS}/${item.slug}`} passHref>
              <StyledLink>{item.name}</StyledLink>
            </Link>
          </Tag>
        )
      })}
    </Container>
  )
}

/**
 * PropTypes
 */

ArticleTags.propTypes = {
  tags: PropTypes.array.isRequired,
}

/**
 * Styles
 */

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  font-weight: bold;
`

const StyledLink = styled.a`
  color: ${props => props.theme.colors.tagText};
  text-decoration: none;
`

const Tag = styled.div`
  padding: 10px;
  margin: 5px;
  background-color: ${props => props.theme.colors.tagBackground};
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: darkgray;
  }

  &:hover ${StyledLink} {
    color: ${props => props.theme.colors.text};
  }
`
