import React from 'react'
import { config } from 'react-awesome-styled-grid'
import { toLower } from 'lodash'
import Link from 'next/link'
import styled from 'styled-components'

import { Routes } from '~/helpers/constants'
import { getLongDate } from '~/helpers/utils'
import { articlePropType } from '~/prop-types'

/**
 * ArticleHeader
 */

export const ArticleHeader = ({ article }) => {
  const { images, title, category, author, createdAt, excerpt, hasFeaturedHero } = article

  return (
    <Container>
      {!hasFeaturedHero && <Image alt={title} src={images.original} />}
      <Title>{title}</Title>
      <Excerpt>{excerpt}</Excerpt>
      <MetaItem>
        <Link href={`${Routes.CATEGORY}/[category]`} as={`${Routes.CATEGORY}/${toLower(category.name)}`} passHref>
          <HeaderLink>{category.name}</HeaderLink>
        </Link>

        {` | Por `}
        <Link href={`${Routes.AUTHOR}/[id]`} as={`${Routes.AUTHOR}/${author.id}`} passHref>
          <HeaderLink>{`${author.name}`}</HeaderLink>
        </Link>
        {` | ${getLongDate(createdAt)}`}
      </MetaItem>
    </Container>
  )
}

/**
 * PropTypes
 */

ArticleHeader.propTypes = {
  article: articlePropType.isRequired,
}

/**
 * Styles
 */

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
`

const Image = styled.img`
  position: relative;
  width: 100%;
  margin: 30px 0 30px;
`

const Title = styled.h1`
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
  color: ${props => props.theme.colors.text};
  margin-top: 0;
  margin-bottom: 0;

  ${props => config(props).media.lg`
    font-size: 32px;
  `}
`

const Excerpt = styled.h2`
  font-size: 18px;
  line-height: 24px;
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.secondaryText};

  ${props => config(props).media.lg`
    font-size: 22px;
  `}
`

const MetaItem = styled.div`
  display: inline-block;
  padding-bottom: 20px;
  font-size: 14px;
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.secondaryText};
  border-bottom: 1px solid ${props => props.theme.colors.separator};
  margin: 10px 0 20px 0;
`

const HeaderLink = styled.a`
  color: ${props => props.theme.colors.secondaryText};
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.colors.secondaryTextHover};
  }
`
