import React, { useCallback } from 'react'
import { config } from 'react-awesome-styled-grid'
import { useRouter } from 'next/router'
import styled from 'styled-components'

/**
 *  NotFoundError
 */

const NotFoundError = () => {
  const router = useRouter()

  const handleBackClick = useCallback(() => {
    router.push('/')
  }, [router])

  return (
    <Container>
      <h1>Oops!</h1>
      <h2>No encontramos lo que estabas buscando</h2>
      <Button onClick={handleBackClick}>Volver al inicio</Button>
    </Container>
  )
}

/**
 * Exports
 */

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  text-align: center;
  height: calc(
    100vh - ${props => props.theme.extras.mobileHeaderHeight} - ${props => props.theme.extras.mobileFooterHeight}
  );
  background: linear-gradient(
    10deg,
    ${props => props.theme.colors.primaryTinted} 0,
    ${props => props.theme.colors.surface} 100%
  );

  ${props => config(props).media.lg`
    height: calc(
      100vh - ${props.theme.extras.desktopHeaderHeight} - ${props.theme.extras.desktopFooterHeight}
    );
  `}
`

const Button = styled.button`
  margin: 30px 0;
  padding: 12px 24px;
  background-color: ${props => (!props.fetching ? props.theme.colors.primary : props.theme.colors.primaryTinted)};
  color: ${props => props.theme.colors.textOverImage};
  font-size: 20px;
  font-weight: 700;
  box-shadow: none;
  border: none;
  width: 200px;
  align-self: center;
  border-radius: 5px;
  cursor: ${props => (!props.fetching ? 'pointer' : 'progress')};

  &:hover {
    background-color: ${props => props.theme.colors.primaryTinted};
  }
`

/**
 * Exports
 */

export default NotFoundError
