import React, { useMemo } from 'react'
import { Col, Container, Row } from 'react-awesome-styled-grid'
import reactHtmlParser from 'react-html-parser'
import Media from 'react-media'
import { useQuery } from 'react-query'
import Bugsnag from '@bugsnag/js'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { fetchPostBySlug, fetchRecentPosts } from '~/api'
import MobileAd from '~/components/ads/MobileAd'
import VerticalAd from '~/components/ads/VerticalAd'
import { ArticleFeaturedHero, ArticleHead, ArticleHeader, ArticleSocialLinks, ArticleTags } from '~/components/article'
import InfinitePosts from '~/components/InfinitePosts'
import Layout from '~/components/Layout'
import NotFoundError from '~/components/NotFoundError'
import { isMobileDeviceType } from '~/helpers/utils'
import { articlePropType } from '~/prop-types'

/**
 * Constants
 */

const QUERY_KEY = 'slug'

/**
 * Helpers
 */

const customHtmlParser = node => {
  const isBlockquote = node.type === 'tag' && node.name === 'blockquote'
  const nodeClass = get(node, 'attribs.class')
  const isTwitterEmbed = nodeClass === 'twitter-tweet'
  const isInstagramEmbed = nodeClass === 'instagram-media'

  if (isBlockquote && isTwitterEmbed) {
    const tweetText = get(node, 'children[0].children[0].data')
    const tweetAuthor = get(node, 'children[1].data')
    const tweetUrl = get(node, 'children[2].attribs.href')
    const tweetDate = get(node, 'children[2].children[0].data')

    return (
      <>
        <Blockquote className="twitter-tweet">
          <BlockquoteText dir="ltr">{tweetText}</BlockquoteText>
          {tweetAuthor}
          <a href={tweetUrl}>{tweetDate}</a>
        </Blockquote>
        <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8" />
      </>
    )
  }

  if (isBlockquote && !isInstagramEmbed) {
    const text = get(node, 'children[0].children[0].children[0].data')

    return (
      <Blockquote>
        <BlockquoteText>
          <strong>{text}</strong>
        </BlockquoteText>
      </Blockquote>
    )
  }

  return undefined
}

/**
 * getServerSideProps
 */

export async function getServerSideProps({ query, res, req }) {
  res.setHeader('Cache-Control', 's-maxage=300, stale-while-revalidate')

  const isMobile = isMobileDeviceType(req)
  const { slug } = query
  try {
    const encodedSlug = encodeURI(slug)
    const [article, recentPosts] = await Promise.all([fetchPostBySlug(QUERY_KEY, encodedSlug), fetchRecentPosts()])

    return { props: { article, initialRecentPosts: recentPosts, isMobile, initialSlug: slug } }
  } catch (e) {
    Bugsnag.notify(e)

    return { props: { isMobile, initialSlug: slug } }
  }
}

/**
 * Article
 */

const Article = ({ article = {}, initialRecentPosts = [], isMobile, initialSlug }) => {
  const { data } = useQuery([QUERY_KEY, initialSlug], fetchPostBySlug, {
    initialData: article,
    enabled: !isEmpty(article),
  })

  const { title, content, slug, tags, hasFeaturedHero, images, passwordProtected } = data

  const hasArticle = !isEmpty(data)
  const hasInitialRecentPosts = !isEmpty(initialRecentPosts)

  const passwordProtectedText = 'El contenido de este post está restringido para administradores.'

  const postContent = useMemo(
    () =>
      reactHtmlParser(content, {
        transform: customHtmlParser,
      }),
    [content]
  )

  return (
    <Media
      queries={{
        small: '(max-width: 992px)',
      }}
      defaultMatches={{ small: isMobile }}
    >
      {matches => (
        <>
          {hasArticle && <ArticleHead data={data} />}
          <Layout isMobile={isMobile}>
            {!hasArticle && <NotFoundError />}
            {hasFeaturedHero && <ArticleFeaturedHero imageSrc={images.original} />}
            {hasArticle && (
              <>
                <MainContainer>
                  <Row justify={{ md: 'center' }}>
                    <ContentContainer xs={12} md={6} lg={8}>
                      {!hasFeaturedHero && <ArticleHeader article={data} />}
                      <Content>
                        {hasFeaturedHero && <ArticleHeader article={data} />}
                        {passwordProtected && <Body>{passwordProtectedText}</Body>}
                        {!passwordProtected && (
                          <>
                            <ArticleSocialLinks title={title} slug={slug} />
                            <Body>{postContent}</Body>
                          </>
                        )}
                        {!isEmpty(tags) && <ArticleTags tags={tags} />}
                      </Content>
                    </ContentContainer>
                    {!matches.small && (
                      <Col sm={12} md={6} lg={4} align="flex-end">
                        <VerticalAd name="article-sidebar" />
                      </Col>
                    )}
                    {matches.small && (
                      <Col sm={12}>
                        <MobileAd centered name="article-mobile" />
                      </Col>
                    )}
                  </Row>
                </MainContainer>
                {hasInitialRecentPosts && (
                  <InfinitePosts
                    initialRecentPosts={initialRecentPosts}
                    fetch={fetchRecentPosts}
                    queryKey={initialSlug}
                    contained
                  />
                )}
              </>
            )}
          </Layout>
        </>
      )}
    </Media>
  )
}

/**
 * PropTypes
 */

Article.propTypes = {
  article: articlePropType,
  initialRecentPosts: PropTypes.array,
  isMobile: PropTypes.bool.isRequired,
  initialSlug: PropTypes.string.isRequired,
}

/**
 * Styles
 */

const MainContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
`

const ContentContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const Blockquote = styled.blockquote`
  padding: 10px 20px;
  margin-box-start: 0.5em;
  margin-box-end: 0.5em;
  margin-inline-start: 20px;
  margin-inline-end: 20px;
  border-left: 5px solid ${props => props.theme.colors.primary};
  box-shadow: 0px 0px 3px #eaeaea;
`

const BlockquoteText = styled.p`
  font-size: 24px;
  line-height: 32px;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 30px;
  min-height: 200px;
  font-family: ${props => props.theme.fonts.secondary};

  a {
    color: ${props => props.theme.colors.primary};
  }

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  div {
    line-break: unset !important;
    white-space: unset !important;
  }

  iframe {
    width: 100% !important;
  }

  @media only screen and (min-width: 680px) {
    img {
      margin-bottom: 20px;
    }
  }
`

/**
 * Exports
 */

export default Article
